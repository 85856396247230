import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';

export type Getters = {
  getSuccess(state: State): any | null;
  getError(state: State): any | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getSuccess: (state) => state.success,
  getError: (state) => state.error,
};
