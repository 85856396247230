import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Models } from '@/core/types/Models';

import { State } from './state';

export type Getters = {
  getModels(state: State): Array<Models>;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getModels: (state) => state.models,
};
