import { MutationTree } from 'vuex';

import { Driver } from '@/core/types/Drivers';

import { State } from './state';
import { DriversMutationTypes } from '@/core/enums/Drivers/Mutations';

export type Mutations<S = State> = {
  [DriversMutationTypes.SET_DRIVER](state: S, payload: Driver): void;
  [DriversMutationTypes.SET_DRIVER_LOADING_PAGE](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [DriversMutationTypes.SET_DRIVER](state: State, driver: Driver) {
    state.driver = driver;
  },
  [DriversMutationTypes.SET_DRIVER_LOADING_PAGE](state: State, payload: Boolean) {
    state.loading = payload;
  },
  [DriversMutationTypes.SET_DRIVER_UUID](state: State, payload: string) {
    state.driver.uuid = payload;
  },
};
