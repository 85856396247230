import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Driver } from '@/core/types/Drivers';

import { State } from './state';

export type Getters = {
  getDriver(state: State): Driver | null;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getDriver: (state) => state.driver,
};
