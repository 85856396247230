import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { ModelsMutationTypes } from '@/core/enums/Models/Mutations';
import { ModelsActionTypes } from '@/core/enums/Models/Actions';
import { LinkTypes } from '@/core/enums/Links';
import axios from 'axios';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [ModelsActionTypes.GET_MODELS](
    { commit }: AugmentedActionContext,
    payload: number
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ModelsActionTypes.GET_MODELS]({ commit }, payload : any) {
    const data = await axios.post(LinkTypes.GET_MODELS, payload);
    try {
      commit(ModelsMutationTypes.SET_MODELS, data.data.data.models);
      commit(ModelsMutationTypes.SET_MODEL_LOADED, true);
    }
    catch(err) {
      console.log(err)
    }
  },
};
