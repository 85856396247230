import { MutationTree } from 'vuex';

import { EstimatedValues } from '@/core/types/EstimatedValues';

import { State } from './state';
import { EstimatedValuesMutationTypes } from '@/core/enums/EstimatedValues/Mutations';

export type Mutations<S = State> = {
  [EstimatedValuesMutationTypes.SET_ESTIMATED_VALUES](state: S, payload: EstimatedValues): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [EstimatedValuesMutationTypes.SET_ESTIMATED_VALUES](state: State, estimatedValues: EstimatedValues) {
    state.estimatedValues = estimatedValues;
  },
};
