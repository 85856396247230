import { MutationTree } from 'vuex';

import { State } from './state';
import { CouponMutationTypes } from '@/core/enums/Coupons/Mutations';

export type Mutations<S = State> = {
  [CouponMutationTypes.SET_COUPON_SUCCESS](state: S, payload: any): void;
  [CouponMutationTypes.SET_COUPON_ERROR](state: S, payload: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [CouponMutationTypes.SET_COUPON_SUCCESS](state: State, success: any) {
    state.success = success;
  },
  [CouponMutationTypes.SET_COUPON_ERROR](state: State, error: any) {
    state.error = error;
  },
};
