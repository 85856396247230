import { ActionTree, ActionContext } from 'vuex';
import ApiService from '@/core/services/ApiService';
import router from '@/router';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { StepperMutationTypes } from '@/core/enums/Stepper/Mutations';
import { StepperActionTypes } from '@/core/enums/Stepper/Actions';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [StepperActionTypes.GET_STEP_VEHICLE_DETAILS](
    { commit }: AugmentedActionContext,
    payload: Object
  ): any;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [StepperActionTypes.GET_STEP_VEHICLE_DETAILS]({ commit }, payload : any) {
    // console.log(payload)
      commit(StepperMutationTypes.SET_STEP_VEHICLE_DETAILS, payload);
  },


};
