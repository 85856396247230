// import { createStore } from "vuex";
// import user from "./modules/user";

// export default createStore({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {user},
// });

import { createStore, createLogger } from 'vuex';

// TODO: How to surpass cyclical dependency linting errors cleanly?
// eslint-disable-next-line import/no-cycle
import { store as vehicles, VehiclesStore, State as VehicleState } from '@/store/modules/Vehicle';
import { store as manufaturers, ManufacturerStore, State as ManufacturerState } from '@/store/modules/Manufacturers';
import { store as models, ModelStore, State as ModelState } from '@/store/modules/Models';
import { store as trimLevels, TrimLevelStore, State as TrimLevelState } from '@/store/modules/TrimLevels';
import { store as estimatedValues, EstimatedValuesStore, State as EstimatedValuesState } from '@/store/modules/EsitmatedValues';
import { store as countries, CountryStore, State as CountryState } from '@/store/modules/Countries';
import { store as drivers, DriverStore, State as DriverState } from '@/store/modules/Driver';
import { store as qoutes, QouteStore, State as QouteState } from '@/store/modules/Qoutes';
import { store as steppers, StepperStore, State as StepperState } from '@/store/modules/Steppers';
import { store as buyNow, BuyNowStore, State as BuyNowState } from '@/store/modules/BuyNow';
import { store as coupon, CouponStore, State as CouponState } from '@/store/modules/Coupons';
import { store as lang, LangStore, State as LangState } from '@/store/modules/Lang';
import { store as checkout, CheckoutStore, State as CheckoutState } from '@/store/modules/Checkouts';

export type RootState = {
  vehicles: VehicleState;
  manufaturers: ManufacturerState;
  models: ModelState;
  trimLevels: TrimLevelState;
  estimatedValues: EstimatedValuesState;
  countries: CountryState;
  drivers: DriverState;
  qoutes: QouteState;
  steppers: StepperState;
  buyNow: BuyNowState;
  coupon: CouponState;
  lang: LangState;
  checkout: CheckoutState;
};


export type Store = VehiclesStore<Pick<RootState, 'vehicles'>>
& ManufacturerStore<Pick<RootState, 'manufaturers'>>
& ModelStore<Pick<RootState, 'models'>>
& TrimLevelStore<Pick<RootState, 'trimLevels'>>
& EstimatedValuesStore<Pick<RootState, 'estimatedValues'>>
& CountryStore<Pick<RootState, 'countries'>>
& DriverStore<Pick<RootState, 'drivers'>>
& QouteStore<Pick<RootState, 'qoutes'>>
& StepperStore<Pick<RootState, 'steppers'>>
& BuyNowStore<Pick<RootState, 'buyNow'>>
& LangStore<Pick<RootState, 'lang'>>
& CouponStore<Pick<RootState, 'coupon'>>
& CheckoutStore<Pick<RootState, 'checkout'>>;

 // Plug in logger when in development environment
// const debug = process.env.VUE_APP_NODE_ENV !== 'production';
// const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  // plugins,
  modules: {
    buyNow,
    checkout,
    countries,
    coupon,
    drivers,
    estimatedValues,
    lang,
    manufaturers,
    models,
    qoutes,
    steppers,
    trimLevels,
    vehicles,
  },
});

export function useStore(): Store {
  return store as Store;
}