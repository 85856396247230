import { store } from "@/store";
import { App } from "vue";
import { QoutesActionTypes } from "@/core/enums/Qoutes/Actions";
import { QoutesMutationTypes } from "@/core/enums/Qoutes/Mutations";

class Qoutes{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Qoutes.vueInstance = app;
    }

    public static async getomprehensiveAndThirdParty(payload: any) {
        return await store
        .dispatch(QoutesActionTypes.GET_COMPREHENSIVE_AND_THIRD_PARTY, payload);
    }

    public static getComparePolicies(payload: any) {
        store.dispatch(QoutesActionTypes.GET_COMPARE_QOUTES, payload)
    }

    public static getCompareData(payload: any) {
        store.commit(QoutesMutationTypes.SET_COMPARE_DATA, payload);
    }

    
    public static async sendCheapestQuotes(payload: any) {
        return await store
        .dispatch(QoutesActionTypes.SEND_CHEAPEST_QUOTES, payload);
    }
    
    public static async getBikeThirdPartyQuotes(payload: any) {
        return await store
        .dispatch(QoutesActionTypes.GET_BIKE_COMPARE_QUOTES, payload);
    }

    public static async sendBikeCheapestQuotes(payload: any) {
        return await store
        .dispatch(QoutesActionTypes.SEND_BIKE_CHEAPEST_QUOTES, payload);
    }
}

export default Qoutes;