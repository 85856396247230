import { GetterTree } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { Manufacturers } from '@/core/types/Manufacturers';

import { State } from './state';

export type Getters = {
  getManufaturer(state: State): Array<Manufacturers>;
}

export const getters: GetterTree<State, RootState> & Getters = {
  getManufaturer: (state) => state.manufacturers,
};
