<template>
    <section class="vechile-detail-section">
            <div class="container">

                <div class="vechile-detail-container">
                    <div class="vechile-detail_row">
                        <div class="row gx-5 justify-content-center">
                            <div class="col-lg-9">
                                <div class="vechile-detail__block">
                                    <div class="row justify-content-center">


                                        <div class="col-lg-11 col-md-7 form-left-column">
                                            <div
                                                class="page-title-block d-flex align-items-center flex-wrap justify-content-between page-title-container">
                                                <div class="page-title mb-0 ">
                                                    <!-- <h2 class="mb-0">Driver Details</h2> -->
                                                </div>
                                                <div class="page-title-desc">
                                                    <h5 class="mb-0">This will only take 1 minute!</h5>
                                                </div>
                                            </div>

                                            <div class="vechile-details-form">
                                                <form>
                                                    <div class="vechile-details-list">
                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>Date of Birth</label>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <div class="row-input">
                                                                            <div class="row gx-20">
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>Nationality</label>
                                                                </div>
                                                                <div class="label-block_question position-relative">
                                                                    <a class="popper" data-bs-trigger="hover"
                                                                        placement="right" data-bs-toggle="popover">
                                                                        <div class="icon">
                                                                            <span class="icon-question-icon"></span>
                                                                        </div>
                                                                    </a>

                                                                    <div class="popper-content-block d-none">
                                                                        <div class="popper-content">
                                                                            <h3>Nationality</h3>
                                                                            <p>Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard
                                                                                dummy
                                                                                text ever since the 1500s, when an
                                                                                unknown
                                                                                printer took a galley of type and
                                                                                scrambled
                                                                                it to m
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">

                                                                        <div class="country-select">
                                                                            <select class="select-input-skeleton" disabled></select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>Which country issued your first driving
                                                                        license?</label>
                                                                </div>
                                                                <div class="label-block_question position-relative">
                                                                    <!-- <div class="icon">
                                                                        <span class="icon-question-icon"></span>
                                                                    </div>
                                                                    <div class="title position-absolute">
                                                                        Lorem, ipsum dolor sit amet consectetur
                                                                        adipisicing
                                                                    </div> -->
                                                                    <a class="popper" data-bs-trigger="hover"
                                                                        placement="right" data-bs-toggle="popover">
                                                                        <div class="icon">
                                                                            <span class="icon-question-icon"></span>
                                                                        </div>
                                                                    </a>

                                                                    <div class="popper-content-block d-none">
                                                                        <div class="popper-content">
                                                                            <p>Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard
                                                                                dummy
                                                                                text ever since the 1500s, when an
                                                                                unknown
                                                                                printer took a galley of type and
                                                                                scrambled
                                                                                it to m
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">


                                                                        <select class="select-input-skeleton" disabled>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>Car first registration date</label>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <div class="row-input">
                                                                            <div class="row gx-20">
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label px-0">
                                                                    <label>How many years of Home country driving
                                                                        experience do you have?</label>
                                                                </div>
                                                                <!-- <div class="label-block_question position-relative">
                                                                    <div class="icon">
                                                                        <span class="icon-question-icon"></span>
                                                                    </div>
                                                                    <div class="title position-absolute">
                                                                        Lorem, ipsum dolor sit amet consectetur
                                                                        adipisicing
                                                                    </div>
                                                                </div> -->
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="checkbox-row">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big active">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>How many years of UAE driving experience do
                                                                        you have?</label>
                                                                </div>
                                                                <div class="label-block_question position-relative">
                                                                    <!-- <div class="icon">
                                                                        <span class="icon-question-icon"></span>
                                                                    </div>
                                                                    <div class="title position-absolute">
                                                                        Lorem, ipsum dolor sit amet consectetur
                                                                        adipisicing
                                                                    </div> -->
                                                                    <a class="popper" data-bs-trigger="hover"
                                                                        placement="right" data-bs-toggle="popover">
                                                                        <div class="icon">
                                                                            <span class="icon-question-icon"></span>
                                                                        </div>
                                                                    </a>

                                                                    <div class="popper-content-block d-none">
                                                                        <div class="popper-content">
                                                                            <p>Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard
                                                                                dummy
                                                                                text ever since the 1500s, when an
                                                                                unknown
                                                                                printer took a galley of type and
                                                                                scrambled
                                                                                it to m
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="checkbox-row">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big active">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-3 col-md-6 col-6 col-sm-4">
                                                                            <div class="checkbox-big">
                                                                                <p>0-6 <span
                                                                                        class="d-block">Months</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>When do you want your policy to start?
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <div class="row-input">
                                                                            <div class="row gx-20">
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                                <div
                                                                                    class="col-sm-4 col-4 col-md-4 col-lg-4">
                                                                                    <select class="select-input-skeleton" disabled>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="form-block claims-block-container">
                                                            <div class="label-block mb-0">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 switch-container claims-block">
                                                                    <div class="label-block_label">
                                                                        <label>Have you made any claims with your
                                                                            insurance
                                                                            provider
                                                                            in the last 12 months?
                                                                        </label>
                                                                    </div>
                                                                    <div class="switch-toggle">
                                                                        <input id="a" type="checkbox" disabled>
                                                                        <label for="a">
                                                                            <div class="switch-toggle__switch"
                                                                                data-checked="No" data-unchecked="Yes">
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div class="label-block_question position-relative">

                                                                    <!-- <div class="title position-absolute">
                                                                        Lorem, ipsum dolor sit amet consectetur
                                                                        adipisicing
                                                                    </div> -->
                                                                    <a class="popper" data-bs-trigger="hover"
                                                                        placement="right" data-bs-toggle="popover">
                                                                        <div class="icon">
                                                                            <span class="icon-question-icon"></span>
                                                                        </div>
                                                                    </a>
                                                                    <div class="popper-content-block d-none">
                                                                        <div class="popper-content">
                                                                            <p>Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard
                                                                                dummy
                                                                                text ever since the 1500s, when an
                                                                                unknown
                                                                                printer took a galley of type and
                                                                                scrambled
                                                                                it to m
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- 
                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <div class="form-radio-block">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input" checked
                                                                                    type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault1">
                                                                                <label class="form-check-label"
                                                                                    for="flexRadioDefault1">
                                                                                    Yes
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" name="flexRadioDefault"
                                                                                    id="flexRadioDefault2">
                                                                                <label class="form-check-label"
                                                                                    for="flexRadioDefault2">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->

                                                        </div>


                                                        <div class="form-block">
                                                            <div class="label-block">
                                                                <div class="label-block_label">
                                                                    <label>How many years with no claims? <strong> (Save
                                                                            up to 20%)</strong> </label>
                                                                </div>
                                                                <div class="label-block_question position-relative">
                                                                    <!-- <div class="icon">
                                                                        <span class="icon-question-icon"></span>
                                                                    </div>
                                                                    <div class="title position-absolute">
                                                                        Lorem, ipsum dolor sit amet consectetur
                                                                        adipisicing
                                                                    </div> -->
                                                                    <a class="popper" data-bs-trigger="hover"
                                                                        placement="right" data-bs-toggle="popover">
                                                                        <div class="icon">
                                                                            <span class="icon-question-icon"></span>
                                                                        </div>
                                                                    </a>

                                                                    <div class="popper-content-block d-none">
                                                                        <div class="popper-content">
                                                                            <p>Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard
                                                                                dummy
                                                                                text ever since the 1500s, when an
                                                                                unknown
                                                                                printer took a galley of type and
                                                                                scrambled
                                                                                it to m
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">


                                                                        <select class="select-input-skeleton" disabled>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="form-title">
                                                                <h3 class="mb-0">Confirm Details</h3>
                                                            </div>

                                                        </div>

                                                        <div class="form-block confirm-details">
                                                            <div class="label-block mb-0">
                                                                <!-- <div class="label-block_label">
                                                                    <label>My car is GCC specification & has not been
                                                                        modified.
                                                                    </label>
                                                                </div> -->

                                                                <div
                                                                    class="d-flex justify-content-between w-100 switch-container">
                                                                    <div class="label-block_label">
                                                                        <label>My car is GCC specification & has not
                                                                            been
                                                                            modified.
                                                                        </label>
                                                                    </div>
                                                                    <div class="switch-toggle">
                                                                        <input id="b" type="checkbox" disabled>
                                                                        <label for="b">
                                                                            <div class="switch-toggle__switch"
                                                                                data-checked="No" data-unchecked="Yes">
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-block confirm-details">
                                                            <div class="label-block mb-0">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 switch-container">
                                                                    <div class="label-block_label">
                                                                        <label>My car is for personal use only.
                                                                        </label>
                                                                    </div>
                                                                    <div class="switch-toggle">
                                                                        <input id="c" type="checkbox" disabled>
                                                                        <label for="c">
                                                                            <div class="switch-toggle__switch"
                                                                                data-checked="No" data-unchecked="Yes">
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- 
                                                            <div class="input-block">
                                                                <div class="row">
                                                                    <div class="col-lg-10">
                                                                        <div
                                                                            class="checkboxes d-flex flex-wrap text-uppercase">
                                                                            <div class="checkboxes_item active">
                                                                                <span>yes</span>
                                                                            </div>
                                                                            <div class="checkboxes_item">
                                                                                <span>no</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->

                                                        </div>
                                                        <div class="form-block confirm-details">
                                                            <div class="label-block mb-0">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 switch-container">
                                                                    <div class="label-block_label">
                                                                        <label> Is your current insurance policy
                                                                            active?</label>
                                                                    </div>
                                                                    <div class="switch-toggle">
                                                                        <input id="d" type="checkbox" disabled>
                                                                        <label for="d">
                                                                            <div class="switch-toggle__switch"
                                                                                data-checked="No" data-unchecked="Yes">
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-block confirm-details">
                                                            <div class="label-block mb-0">
                                                                <div
                                                                    class="d-flex justify-content-between w-100 switch-container">
                                                                    <div class="label-block_label">
                                                                        <label>Is the current policy fully
                                                                            comprehensive?</label>
                                                                    </div>
                                                                    <div class="switch-toggle">
                                                                        <input id="e" type="checkbox" disabled>
                                                                        <label for="e">
                                                                            <div class="switch-toggle__switch"
                                                                                data-checked="No" data-unchecked="Yes">
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- end -->
                                                    </div>
                                                    <div class="note-row">
                                                        <div class="note-block bg-white position-relative">
                                                            <p>Your insurer may require extra information to issue your
                                                                policy.
                                                                This may include photographs of your car, your RTA
                                                                passing certificate and, in some cases they may need to
                                                                inspect your car</p>
                                                            <div class="icon"><img src="images/icons/i-icon.png"
                                                                    class="img-fluid">
                                                            </div>
                                                        </div>
                                                        <div class="submit-btn ps-0 text-md-end text-center">
                                                            <div class="row">
                                                                <div class="col-lg-10">
                                                                    <button type="submit" class="btn">Proceed </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </form>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>