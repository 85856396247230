import { Vehicle, Bike } from '@/core/types/Vehicles';
import moment from "moment"


export type State = {
  vehicle: Vehicle;
  bike: Bike;
  loading: Boolean;
  counter: number
}

export const state: State = {
  vehicle: {
    car_year: new Date().getFullYear(),
    is_new: 2,
    manufacturer_id: -1,
    model_id: -1,
    trim_level_id: -1,
    registration_emirate: 8,
    phone_number: null,
    email: null,
    name: null,
    phone_country_code: '+974',
    car_values: 0,
    is_car: 1,
    lead_source: 'website',
    btm_source: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    sid: '',
    customer_source_url: '',
    is_wizard: true
  } as Vehicle,
  bike: {
    bike_year: 0,
    engine_capacity: -1,
    phone_number: null,
    email: null,
    name: null,
    phone_country_code: '+974',
    registration_emirate: 8,
    nationality:null,
    dob: null,
    // dob_month: null,
    // dob_day: null,
    // dob_year: null,
    lead_source: 'website',
    btm_source: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    sid: '',
    customer_source_url: '',
    uae_driving_experience: 4,
    policy_start_date: moment().format('YYYY-MM-DD'),
    // policy_start_date_month: new Date().getMonth() + 1,
    // policy_start_date_day: new Date().getDate(),
    // policy_start_date_year: new Date().getFullYear(),
    is_wizard: true
  } as Bike,

  loading: false,
  counter: 0
    
};
