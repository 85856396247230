import { store } from "@/store";
import { App } from "vue";
import { DriversActionTypes } from "@/core/enums/Drivers/Actions";
import { DriversMutationTypes } from "@/core/enums/Drivers/Mutations";

class Drivers{

    public static vueInstance: App;

    public static init(app: App<Element>) {
        Drivers.vueInstance = app;
    }

    public static async postDriverDetails(payload: any) {
        return await store
        .dispatch(DriversActionTypes.POST_DRIVER, payload)
    }

    public static setDriverUUID(payload: any) {
        store.commit(DriversMutationTypes.SET_DRIVER_UUID, payload);
    }

    public static setInitialDriverDetails() {
        store.dispatch(DriversActionTypes.GET_DRIVER);
    }
}

export default Drivers;